import Link from "@/components/Link";
import Picture from "@/components/Picture";
import Badge from "@/components/Badge";
import IconBox from "./IconBox";

const DocsItem = ({ item }) => {
  const {
    id,
    href,
    title,
    description,
    section,
    icon,
    color,
    image,
    alt,
    authors,
    article,
  } = item;

  return (
    <Link
      id={id}
      href={href}
      block
      className="group w-full h-full bg-dark-100 hover:bg-dark-200 outline outline-dark-50 opacity-90 hover:!opacity-100 rounded-lg"
    >
      <article className="flex flex-col items-stretch justify-between">
        {image ? (
          <Picture
            src={image.src}
            width={image.width}
            height={image.height}
            sizes="100vw, (min-width: 768px) 50vw, (min-width: 1024px) 33vw"
            className="h-48 w-full object-cover bg-white/5"
            alt={alt || null}
          />
        ) : null}
        <div className="mb-auto p-4 md:p-8">
          <div className="flex items-center space-x-4">
            {icon ? <IconBox icon={icon} color={color} size="lg" /> : null}
            <h2 className="pr-4 text-lg md:text-xl xl:text-2xl text-1 font-medium">
              {title}
            </h2>
          </div>
          {description ? (
            <p className="text-3 text-sm md:text-base xl:text-lg mt-2">
              {description}
            </p>
          ) : null}
          {authors ? (
            <div className="flex items-center justify-start gap-4 mt-6">
              {authors.map((author, index) => (
                <p
                  key={index}
                  className="flex items-center space-x-2 text-5 text-xs md:text-sm xl:text-base"
                >
                  {author.image ? (
                    <Picture
                      src={author.image.src}
                      width={author.image.width}
                      height={author.image.height}
                      className="h-8 w-8 rounded-sm border-2 border-violet-20 dark:border-violet-900"
                      alt={`${author.name} Profile Image`}
                    />
                  ) : null}
                  {author.name}
                </p>
              ))}
            </div>
          ) : null}
          {article && section ? (
            <div className="mt-4">
              <Badge value={section} size="sm" className="text-brand-purple" />
            </div>
          ) : null}
        </div>
      </article>
    </Link>
  );
};
export default DocsItem;
