// const defaultSizes = [160, 320, 480, 720, 960, 1280, 1920];

// function getSrcSet({ src, width, format }) {
//   let srcSet = null;
//   for (let i = 0; i < defaultSizes.length; i++) {
//     const w = defaultSizes[i];
//     if (width > w) {
//       if (!srcSet) {
//         srcSet = "";
//       }
//       srcSet += `${src}?${
//         format === "default" ? "" : `format=${format}&`
//       }width=${w} ${w}w,`;
//     }
//   }
//   if (!srcSet) {
//     srcSet = `${src}?${format === "default" ? "" : `format=${format}`} 1x`;
//   }
//   return srcSet;
// }

export default function Picture({
  src,
  width,
  height,
  sizes = "100vw",
  alt = "",
  className = "",
  priority = false,
  rendering = "auto",
}) {
  // TODO: Get sources from media_id or use query params to deliver alt formats
  // TODO: If ends in .svg, use <img> instead of <picture>
  // let formats = ["webp", "avif", "default"];
  return (
    <picture className="w-full">
      {/* {formats.map((format) => {
        if (format === "default") { */}
      {/* return ( */}
      <img
        // key={format}
        src={src}
        // srcSet={getSrcSet({ src, width, format: "default" })}
        width={width}
        height={height}
        sizes={sizes}
        loading={priority ? "eager" : "lazy"}
        decoding={priority ? "auto" : "async"}
        fetchPriority={priority ? "high" : "low"}
        alt={alt}
        className={className}
        style={{
          imageRendering: rendering,
        }}
      />
      {/* );
        } else {
          return (
            <source
              key={format}
              srcSet={getSrcSet({ src, width, format })}
              type={`image/${format}`}
            />
          );
        }
      })} */}
    </picture>
  );
}
